html, body {
  margin: 0;
}
body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, .code {
  font-family: 'Source Code Pro', monospace;
}